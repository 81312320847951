body {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

.bold-text {
    font-weight: 600;
}

.dashboard img {
    width: 100%;
    border-radius: 17px;
}




.dashboard img {
    position: relative;
}

.banner-arera {
    background-image: url('./Img/images/staduim-ground.png');
    background-repeat: no-repeat;
    background-size: cover;
    color: #ffffff;
    min-height: 786px;
    /* background-image: linear-gradient(to right bottom, #f2592a, #cf324f, #99275f, #5b2758, #261e3e); */
}


.btn.btn-custom {
    color: #f2592a;
    font-size: 20px;
    font-weight: 500;

}

.heading-area h1 {
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    word-break: break-all;
}

.heading-area {
    padding-top: 3rem;
}

.connect-team {
    background-color: #EDEDED;
}

button.btn.btn-custom {
    color: #f2592a;
    font-weight: 900;
}

.league.start {
    /* width: 169px;
    height: 56px; */
    padding: 18px 32px 14px 34px;
    border-radius: 10px;
    background: #27AE60;
    border: 0;
    color: #fff;
}

.signin-btn:hover i {
    color: #fff;
}

.icons i.fa-brands:hover {
    color: #ff57229c;
    transition: 0.3s ease-in-out;
}

.yourself {
    background-image: url("./Img/images/3\ \(2\).png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5rem 0;
}

.banner-text h1 {
    color: #fff
}

.footer-widget .nav li.nav-item {
    padding-right: 51px;
    padding-left: 15px;
}

.footer-widget .nav li.nav-item a:hover {
   
    color: #f1582b;
    /* font-weight: 600; */
    transition: 0.3s ease-in-out;
}

.footer-widget a {
    color: #767676;
    text-decoration: none;
}

.Subscribe-btn {
    background-color: #f2592a;
    border: 1px solid #f2592a;
    color: #fff;
    width: 122px;
    height: 45px;
    border-radius: 12px;
}

.send-btn button {
    background: rgb(40, 32, 65);
    border: none;
    width: 95%;
    color: rgb(255, 255, 255);
    padding: 13px;
    font-size: 18px;
    font-weight: 700;
    border-radius: 6px;
}

.location span {
    font-size: 14px;
}

.contact-form h2 {
    text-align: center;
    padding-top: 19px;
}

.heading h1 {
    color: #f1582b;
    font-size: 52px;
    font-weight: 700;
}

 label.form-label {
    font-size: 17px;
    margin-bottom: 8px !important;
}

.icons a i.fa-brands {
    font-size: 30px;
    padding-right: 66px;
    color: #282041de;
}

.footer {
    background-color: #F0F0F0;
    margin-top: -3rem;
}

.desclaimer {
    color: #fff;
    background-color: rgb(40 32 65);
    text-align: center;
    padding-top: 29px;
    padding-bottom: 29px;
}

.footer-text h2 {
    font-size: 16px;
}

.testimonial {
    padding: 32px 15px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 13px;
}

.testimonial .pic {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 25px;
    overflow: hidden;
}

.testimonial .pic img {
    width: 100%;
    height: auto;
}

.testimonial .description {
    padding: 0 15px;
    font-size: 13px;
    color: #808080;
    line-height: 26px;
}

.testimonial .testimonial-title {
    font-size: 14px;
    letter-spacing: 3px;
    color: #000;
    text-transform: uppercase;
    position: relative;
}

.owl-theme .owl-controls .owl-page span {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 20px;
    background: #fff;
    margin: 5px 7px;
    opacity: 0.5;
}

section.testimonials {
    background-color: #f2a804;
}

.white-text {
    color: #FFF;
}


/* faq */

.fqas .accordion-item {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 18px;
    transition: 0.4s;
    font-weight: 600;
    margin-bottom: 15px;
    border-radius: 9px;
}

.fqas button.accordion-button {
    background: transparent;
    font-size: 22px;
    font-weight: 600;
    width: 100%;
    display:inherit;
    box-shadow: none;
}

.fqas {
    background: #28214126;
    padding-bottom: 2rem;
}

.fqas .active,
.accordion-item:hover {
    background-color: #ccc;
}

.fqas .accordion-button:after {
    content: '\002B';
    color: rgb(40, 33, 66);
    font-weight: bold;
    float: right;
    margin-left: 5px;
    font-size: 24px;
}

.fqas .accordion-button[aria-expanded="true"]:after {
    content: "\2212";
    color: #f1582b;
    position: relative;
    right: 10px;
    background-image: none;
}

.fqas .panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    margin-bottom: 1rem;
}

.fqas .accordion-button::after {
    background-image: none;
    background-repeat: no-repeat;
    transition:none;
}

.fqas .panel p {
    padding-top: 10px;
}

button.accordion-item {
    margin-bottom: 21px;
    border-radius: 9px;
}

.footer-widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.form-check-input:checked {
    background-color: #f2592a;
    border-color: #f2592a;
    font-size: 20px;
}

.form-check-input[type=checkbox] {
    font-size: 20px;
}

.form-check {
    padding-top: 32px;
}

.fixed-top .header-menu li.nav-item .nav-link {
    font-size: 1.2rem;
    color: #000;
    transition: all 0.4s ease-in-out;
    padding: 10px 20px;
}

.fixed-top .header-menu li.nav-item .nav-link:hover {
    color: #f2592a;
    transition: all 0.4s ease-in-out;
}

.privacy a {
    color: #e56a3d;
    text-decoration: none;
}

.listing li {
    padding-bottom: 5px;
    list-style: none;
}

@media screen and (min-width:992px) {
    .header-menu .navbar-brand-img {
        max-width: none;
        width: auto;
    }

    .header-menu button.navbar-toggler {
        display: none;
    }

    .header-menu.logo-change {
        display: flex !important;
        justify-content: space-between;
    }
}

.icon i.fa-solid {
    color: #f2592a;
    font-size: 22px;
}

.icon span {
    color: #fff;
    font-size: 18px;
    margin-left: 10px;
}

.header-menu {
    background-color: #f7efefc2;
}


.logo img {
    width: 126px;

}

.header-menu li.nav-item .nav-link:hover {
    color: #f1582b;
}

/* 25-09-2023 */

.banner-btn .tounament {
    background-color: #27ae60;
    color: #fff;
    border: 0px;
    font-weight: 400;
    width: 246px;
    height: 42px;
    padding: 10px 24px 10px 24px;
    border-radius: 6px;
}

img.basket-img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
}
.banner-btn .league.start {
    background-color: #f2592a;
    color: #fff;
    border: 0px;
    font-weight: 400;
    width: 207px;
    height: 42px;
    padding: 10px 24px 10px 24px;
    border-radius: 6px;
}

.teaxt-area h1 {
    width: 623px;
    height: 104px;
}

.teaxt-area {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 11rem;
    left: 11rem;
}

.teaxt-area p {
    font-size: 20px;
    line-height: 32px;
}

.coach-img img {
    width: 100%;
    height: auto;
}

button.tounament {
    padding: 18px 23.5px 18px 23.5px;
    border-radius: 10px;
    border: 0;
    background-color: #f2592a;
    color: #fff;
}

.team-connect-team p {
    padding: 18px 24px 18px 24px;
}

.carousel-item .download-btn {
    margin-left: -0.6rem;
}

.carousel-item img.d-block.w-100 {
    height: 790px;
    object-fit: cover;
}

.footer-text a {
    color: #fff;
    text-decoration: none;
}

.terms-conditions {
    margin-top: 8rem;
}

.coach {
    background: #f0f0f094;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background: transparent !important;
    color: #ee5729;
}


/* .nav-item a.active::after {
    content: "";
    display: block;
    position: absolute;
    width: 72%;
    height: 7%;
    background-color: #282041d6;
} */

.nav-item a {
    position: relative;
}


/**************************** coming soon **********************************/

.coming-soon-logo img {
    width: 178px;
    margin-top: 3rem;
}

.content-txt h1 {
    font-size: 67px;
    font-weight: 800;
    font-family: math;
    line-height: 119px;
    text-align: center;
    margin-top: 2rem;
    color: #780100e8;
}

#myBtn {
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    font-size: 18px;
    border: none;
    outline: none;
    background-color: #f1582b;
    color: white;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 4px;
}

ul.buiness-bullets li {
    padding-bottom: 0.7rem;
    padding-top: 0.2rem;
}

.founder {
    background: rgb(40, 32, 65);
    color: rgb(255, 255, 255);
    width: 100%;
    padding-top: 27px;
    padding-bottom: 8rem;
    border-radius: 11px;
}

.founder-details-icon {
    padding: 0px 36px;
}

.form {
    font-size: 14px;
}

.form-control:focus {
    box-shadow: none;
    border-color: #656a71;
}

.founder-details-icon .fa-solid {
    color: #282041;
    background-color: #fff;
    width: 35px;
    height: 35px;
    font-size: 21px;
    border-radius: 50%;
    padding: 7px 7px;
    text-align: center;
}

.founder-details-icon a {
    color: #fff;
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    
}

.Numerous-bullets ul>li {
    padding: 7px 0px 6px 3px;
}

.footer-widget ul {
    display: flex;
    justify-content: space-around;
    text-align: center;
    align-items: center;
}

.connect-team-pic img {
    width: 100%;
    margin-bottom: 12px;
    max-width: 297px;
}

.player .carousel-indicators [data-bs-target] {
    background-color: #ee5729;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.player .carousel-indicators {
    bottom: -4rem;
}

/* 04-10-2023 */

.CaptchaWrap {
    position: relative;
}

.CaptchaTxtField {
    border-radius: 5px;
    border: 1px solid #ccc;
    display: block;
    box-sizing: border-box;
}

#UserCaptchaCode {
    padding: 15px 10px;
    outline: none;
    font-size: 18px;
    font-weight: normal;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
}

#CaptchaImageCode {
    text-align: center;
    margin-top: 15px;
    padding: 0px 0;
    width: 269px;
    overflow: hidden;
}

.capcode {
    display: block;
}

.ReloadBtn {
    background: url('https://cdn3.iconfinder.com/data/icons/basic-interface/100/update-64.png') left top no-repeat;
    background-size: 100%;
    width: 32px;
    height: 32px;
    border: 0px !important;
    /* outline none; */
    position: absolute;
    bottom: 30px;
    left: 274px;
    outline: none;
    cursor: pointer;
    /**/
}

.error {
    color: red;
    font-size: 12px;
    display: none;
}

.success {
    color: green;
    font-size: 18px;
    margin-bottom: 15px;
    display: none;
}

.detail {
    background-color: #fff;
    padding: 29px 0px;
    text-align: center;
}

.nav-link svg {
    margin-left: 1rem;
    font-size: 23px;
    fill: #e25d35;
}

.modal-heading {
    color: #282041;
    font-size: 22px;
    font-weight: 600;
}

.modal-body p {
    font-size: 19px;
}

.form-details {
    background-color: #29214317;
    margin: auto;
    width: 72%;
    padding: 42px 71px;
}

.form-details .form-check-input:checked {
    background-color: #f25824;
    border-color: #f15929;

}

.form-details .form-check-input {
    font-size: 20px;

}

button.btn.btn-primary {
    padding: 12px 45px 11px 42px;
    border-radius: 10px;
    background-color: #f2592a;
    color: #fff;
    border: 0px;
    text-align: center;
    white-space: nowrap;
}

.form-details .inputform .form-control {
    padding: 0.9rem 0.75rem;
}

.delete-account img {
    width: 27%;
}

.delete-account i.fa-solid {
    font-size: 44px;
    color: #c02424;
}

.warning-header h3 {
    color: #c02424;
    font-size: 25px;
    font-weight: 600;
}

.logo-img img {
    width: 100%;
}

table.table.tab-table span {
    margin-left: 12px;
}
.logos {
    background-color: aliceblue;
}

.logo-img {
    background: #fff;
}

.logo-img {
    background: #fff;
    width: 100%;
    text-align: center;
    height: 169px;
    padding-top: 30px;
    margin-bottom: 14px;
}

.logo-img.sticker-img {
    padding: 0px;
}

.logo-img img {
    width: 54%;
}

.image-down img {
    margin-top: 1rem;
    width: 74%;
}

img.gallery-img {
    width: 100%;
    height: 332px;
    object-fit: cover;
    border-radius: 15px;
}

.dashboard {
    position: relative;
}
.mobile-video {
    position: absolute;
    width: 171px;
    right: 0px;
    top: 67px;
}

.mobile-video video {
    position: absolute;
    max-width: 154px;
    top: 201px;
    right: 9px;
    border-radius: 8px;
}

/* contact-form */
  .box p {
    font-size: 14px;
    text-align: center;
  }
  
  .group {
    position: relative;
    margin-bottom: 40px;
    /* margin-left: 40px; */
  }
  
  .inputMaterial {
    font-size: 18px;
    padding: 22px 10px 0px 5px;
    display: block;
    width:100%;
    border: none;
    border-bottom: 1px solid #757575;
  }
  
  .inputMaterial:focus {
    outline: none;
  }

  .inputMaterial::-webkit-outer-spin-button,
  .inputMaterial::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .inputMaterial[type=number] {
        -moz-appearance: textfield;
    }
  
  .conatct-box {
    background: #fff;
    /* padding-top: 14px; */
    padding-bottom: 8px;
    padding-right: 20px;
    padding-left: 17px;
    border-radius: 18px;
    width: 86%;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}
  /* LABEL ======================================= */
  .contact-form label {
    color: #999;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  
  /* active state */
  .inputMaterial:focus ~ label, .inputMaterial:valid ~ label {
    top: -10px;
    font-size: 16px;
    color: #f1582b;
    font-weight: 600;
  }
  
  /* BOTTOM BARS ================================= */
  .bar {
    position: relative;
    display: block;
    width:100%;
  }
  
  .bar:before, .bar:after {
    content: "";
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background:rgb(40 32 65 / 77%);
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  
  .bar:before {
    left: 50%;
  }
  
  .bar:after {
    right: 50%;
  }
  
  /* active state */
  .inputMaterial:focus ~ .bar:before, .inputMaterial:focus ~ .bar:after {
    width: 50%;
  }
  
  /* active state */
  .inputMaterial:focus ~ .highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
  }
  
  /* ANIMATIONS ================ */
  @-webkit-keyframes inputHighlighter {
    from {
      background: #5264AE;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @-moz-keyframes inputHighlighter {
    from {
      background: #5264AE;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  @keyframes inputHighlighter {
    from {
      background: #5264AE;
    }
    to {
      width: 0;
      background: transparent;
    }
  }
  
  /* body {
    background: #eceff1;
  } */
  
  @keyframes bounce-nds {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @keyframes bounce-out-nds {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0);
    }
  }
  .form-submit-btn button {
    padding: 19px 37px;
    background-color: #282041;
    color: #fff;
    border: 0;
    border-radius: 5px;
    float: right;
}
 
.contact {
    background: #f0f8ff7a;
}

.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
    bottom: -41px !important;
}
  
  /* .swiper-button-next::after {
    display: none;
  } */

.swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  .swiper{
    overflow: unset;
    overflow-x: clip;
    position: unset;
  }
  .swiper-button-next:after,   .swiper-button-prev:after{
    font-size: 15px;
    color: #fff !important;
  }
  .swiper-button-next,   .swiper-button-prev {
    width: 30px;
    height: 30px;
    background-color: #f36a40;
    border-radius: 50%;
}
.swiper-button-next {
    right: -30px;
}
.swiper-button-prev{
    left: -30px;
}
.slider-img{
    position: relative;
}
.swiper-pagination-bullet-active {
    background: #f1582b !important;
}
.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}


/* deatil page */


.deatil-banner img {
    width: 100%;
}
.quiries {
    padding: 20px 32px 20px 32px;
    border-radius: 12px;
    background-color: #E9E9E9;
    box-shadow: 0px 4px 16px 0px #00000029;

}

.banner-d{
    background-image: url("./Img/images/detail.bg.png");
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;  
    margin-top: 8.5rem;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 91px;
}
.banner-d h3 {
    color: #fff;
    font-size: 41px;
}
.banner-d p {
    font-size: 15px;
    color: #fff;
}
.banner-d p {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 200;
}
.banner-d p strong {
    font-weight: 500;
}
.download-the-app .inner-wrap{
    background-color: #E9E9E9;
    display: flex;
}
.download-the-app .btns {
    display: flex;
}
.download-the-app .btns .playstore{
    cursor: pointer;
}
.download-the-app .btns .ios{
    cursor: pointer;
}
.download-the-app .inner-wrap {
    background-color: #E9E9E9;
    display: flex;
    border-radius: 10px;
    padding: 30px;
    justify-content: space-between;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}
.inner-wrap h4 {
    color: #25242C;
    font-size: 34px;
}
.inner-wrap p {
    font-size: 18px;
    margin-bottom: 0;
}
.download-the-app {
    margin: 20px 0;
}
.listing-wrap{
    box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.14);
    border-radius: 12px;
    margin: 32px 0;

}

.top-navs-list {
    margin-bottom: 30px;
    padding: 8px 1px;
}
.top-navs {
    color: #25242C;
}
.top-navs:hover, .top-navs:focus{

    border-color: transparent !important;
}
.top-navs:focus-visible{
    outline: 0;
}
.top-navs{
    border-bottom: 2px solid #000;
    border-color: transparent !important;
}
.top-navs-list .nav-link{
    margin-bottom: 0;
    background: transparent;
    border: 0;
    padding: 7px 37px;
}
.top-navs.active {
    border: 0;
    border-bottom: 2px solid #000;
    border-color: #f2592a !important;
    color: #f2592a !important;
}
.sub-nav li button {
    border: 0 !important;
}
.sub-nav {
    border: 0 !important;
    padding-left: 2rem;
}
.weekly-d {
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 10px;
}
.weekly-d p{
    margin-bottom: 0;
}
.weekly-d {
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 10px;
    font-weight: 500;
    color: #25242C;
    font-size: 16px;
}
.weekly-d p {
    font-weight: 300;
    font-size: 15px;
    color: #838383;
}
.nav-link.active .weekly-d{
    border: 1px solid #f1592a;
    color: #f1582a;
}
table.table.tab-table thead tr th {
    background-color: #F4F4F4;
    border: 0;
    padding: 10px 24px;
}
.sub-nav{
    margin-bottom: 30px;
}
.listing-wrap{
    padding: 0;
}
.sub-nav .nav-link {
    padding-right: 1px;
    padding-top: 3px;
}
table.table.tab-table tbody tr td {
    padding: 20px 27px;
    font-weight: 500;
}
table.table.tab-table tbody tr td p {
    color: #838383;
    font-weight: 300;
    font-size: 14px;
}
.top-navs-list .nav-link:hover{
    color: #000;
}
.org-card {
    background-color: #EEEEEE;
    border-radius: 12px 12px 0 0;
  
}
.organiser .row{
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    border-radius: 12px 12px 0 0;
}
.org-details {
    display: flex;
    padding: 20px;
    align-items: center;
}

.org-content .email p {
    margin-left: 7px;
    margin-bottom: 0px;
}
.org-content .email {
    display: flex;
    margin-left: 12px;
}
.org-content .phone p {
    margin-left: 7px;
    margin-bottom: 0px;
}
.org-content .phone {
    display: flex;
    margin-left: 12px;
    margin-top: 7px;

}
.bio {
    padding: 15px;
}
.bio p {
    font-size: 15px;
    max-width: 60%;
    font-weight: 300;
    color: #838383;
}
.org-content {
    padding: 10px 10px;
}
.event-imgs {
    display: flex;
}
.card-details p {
    text-align: end;
    margin-top: 15px;
}
.card-details {
    margin-right: 12px;
}
.org-name p {
    margin-bottom: 0;
    font-size: 22px;
    padding: 15px;
}
.org-name p span {
    color: #27AE60;
}
.conact-btn {
    margin-top: 32px;
}
.league-history .nfl-win{
 
    border-radius: 12px;
    background: #FFF;
}
.league-history {
    max-width: 375px;
    min-height: 330px;
    width: 100%;
    /* border: 1px solid #D9D9D9; */
    border-radius: 12px;
}
.e1 img {
    width: 41px;
    height: 41px;
    object-fit: cover;
    border-radius: 50%;
    object-position: top center;
}
.nfl-win p {
    width: 198px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
}
.winner_detail_image {
    width: 100%;
    display: flex;
    justify-content: center;
    gap:15px;
}
.winner_detail_image h4 {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 0;
}
.winner_detail_image p{
    color: #F2592A;
}
.winner_detail_image img{
    width: 100%;
    border-radius: unset !important;
}
.completed_winner_detail {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
hr.line_between_image {
    width: 1px;
    border: 1px solid rgba(105, 103, 103, 0.30);
    height: 60px;
}
.win {
    display: flex;
    justify-content: center;
}
.trophy {
    display: flex;
    align-items: center;
}
.nfl-win p {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
}
.league-history .nfl-win {
    padding: 10px 20px 10px 40px;
    border-radius: 12px;
    background: #FFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.completed {
    background-color: #838383;
    color: #fff;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    line-height: 12px;
    margin: 0;
    padding-bottom: 12px;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.ongoing_startDate h6{
    color: #000;
    font-size: 24px;
}
.ongoing_startDate h4{
    color: #000;
    font-size: 35px;
    font-weight: 700;
}
.ongoing_startDate {
    display: flex;
    flex-direction: column;
}
.org-pro strong{
    color: #333333;
    font-weight: 400;
}
.org-pro strong p{
    margin-bottom: 0;
    font-size: 12px;
    color: #27AE60;
}
.org-pro {
    padding-bottom: 15px;
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    padding: 20px;
    padding-top: 0;
}
.org-pro .dates_org_pro span {
    color: #fff;
    font-weight: 400;
    background: #838383;
    padding: 3px 14px;
    border-radius: 15px;
    margin: 0;
    font-size: 12px;
    line-height: 22px;
    text-transform: capitalize;
}
.org-pro .dates_org_pro span.orange{
    background: #F2592A;
}

.org-pro .dates_org_pro span.green{
    background: #27AE60;
}

.tournamnt-slider .swiper {
    padding: 0 30px;
}

.org-pro .dates_org_pro span strong {
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-transform: capitalize;
    letter-spacing: 1px;
}
.card {
    position: relative;
}
.football_icon {
    position: absolute;
    left: -10px;
    top: -22px;
    
}
.football_icon img {
    max-width: 60px;
    max-height: 50px;
}
.org-pro-detail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.dates_org_pro span {
    display: block;
}
hr.line-start-end {
    width: 1px;
    height: 20px;
    margin: 0 auto;
    border: 1px dashed #000;
    margin-top: 3px;
}
.team-n h2 {
    font-weight: 600;
    margin-bottom: 0;
}
.team-n {
    margin-left: 15px;
}
.team-n p {
    color: #F1582B;
    font-size: 19px;
}
section.tournamnt-slider h3 {
    font-weight: 500;
    font-size: 35px;
}
a.a-link {
    text-decoration: none;
    color: #f2592a;
}
section.tournamnt-slider {
    /* margin-bottom: 90px; */
    position: relative;
    background: #f0f0f094;
    padding: 110px 0px;
}
section.tournamnt-slider .row {
    margin-bottom: 50px;
}
.ongoing-legue .nfl-win {
    background: #27AE60;
    border-radius: 10px 10px 0 0;
}
.ongoing-legue .nfl-win {
    background: #27AE60;
    border-radius: 10px 10px 0 0;
}
.ongoing-legue .ongoing-active {
    background: #fff;
    color: #27AE60;
}
.nfl-win.onroll_green{
    background: #27AE60 !important;
}
.org-details img {
    width: 139px;
    height: 139px;
    border-radius: 50%;
    object-fit: cover;
}
.ongoing-legue .nfl-win p {
    color: #fff;
}
.completed.ongoing-active {
    background: #2dae60;
    color: #fff;
    font-weight: 400;
    padding: 10px 20px;
    line-height: 12px;
    margin: 0;
    padding-bottom: 12px;
    text-transform: capitalize;
}
.dates_org_pro {
    display: flex;
    flex-direction: column;
}
.t1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.t-details {
    text-align: center;
}
.trophy.teams-vs {
    margin: 0 14px;
    padding: 2px 26px;
    border-radius: 12px;
    border: 1px solid #D9D9D9;
}
.trophy {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.t-details p {
    margin-bottom: 0;
    color: #000;
}
.t-details span {
    color: #aaa;
    font-size: 13px;
}
.t1 span{
    color: #aaa;
}
.upcoming-le p {
    text-align: center;
    font-size: 24px;
    color: #959595;
    margin-bottom: 0;
}
.upcoming-le strong {
    font-size: 36px;
}
.upcoming-le{
    text-align: center;
    /* padding: 18px 0; */
}
.league-history.upcoming-l .nfl-win {
    background-color: #FFE49D;
    border-radius: 10px 10px 0 0;
}
.league-history.upcoming-l .completed {
    background-color: #fff;
    color: #000;
}
.win.t-win {
    padding: 15px 4px;
}
.league-history img {
    display: block;
    width: unset;
    object-fit: cover;
    /* width: 80px;
    height: 80px; */
    /* border-radius: 50%; */
    object-position: top center;
}
.org-pro img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    border-radius: 50%;
    object-position: top center;
}
.league-history{
    display: unset;
}
.tournamnt-slider span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #282142 !important;
}
.e1 {
    cursor: pointer;
}
.standing tr td{
font-weight: 400 !important;
}
@media(max-width: 768px){
    .banner-arera{
        height: auto;
    }
    .download-the-app .inner-wrap{
        flex-direction: column;
    }
    .inner-wrap .content {
        text-align: center;
    }
    .download-the-app .btns {
        display: flex;
        justify-content: center;
        margin-top: 21px;
    }
    .card-wrap {
        flex-direction: column;
    }
    .card-details{
        padding: 22px;
        padding-top: 0;
        display: flex;
        align-items: center;
    }
    .card-details p{
        text-align: start;
    }
    .org-details{
        padding-bottom: 0;
    }
    .card-details .conact-btn {
        margin-top: 0;
    }
    .card-details .conact-btn{
        margin-left: 24px;
    }
    .event-imgs{
        margin-left: 16px;
    }
    .bio p{
        max-width: 97%;
    }
}

@media(max-width: 664px){
    .league-history{
        max-width: 375px;
    }
}

@media(max-width: 600px){
    .card-details{
        align-items: flex-start;
    flex-direction: column;
    }
    .event-imgs {
        margin-left: 0;
    }
    .card-details .conact-btn {
        margin-left: 0;
        margin-top: 23px;
    }
}

/* .text {
    padding: 5px;
    position: relative;
    left: 75px;
    top: 5px;
    background: #bebebe;
    width: 100px;
    height: 15px;
    color: black;
    display: none;  
    
} 
div.container:hover .text {
    display: block; 
    
   
} */

.inner-wrap.details-views label {
    position: inherit;
    color: #000;
}
.details-views .content {
    width: 100%;
    max-width: 461px;
    margin-left: -60px;
}

.form-input-background{
    background-color: #E6E6E6!important;
}
.custom-select option {
    background: #E6E6E6; 
    border-radius: 1em!important;
}
/* /// DECORATION CSS /// */
.cont_select_center {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -30px;
    margin-left: -150px;
}
.cont_heg_50 {
    position: absolute;
    height: 50%;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #fd7b52;
}
/* /// END DECORATION CSS /// */
.icon_select_mate {
    position: absolute;
    top: 8px;
    right: 2%;
    font-size: 16px;
    height: 22px;
    transition: all 275ms;
}
.select_mate {
    position: relative;
    float: left;
    width: 100%;
    color: #777;
    font-weight: 300;
    background-color: #fff;
    /* box-shadow: 1px 2px 10px -2px rgba(0, 0, 0, 0.3); */
    border-radius: 3px;
    transition: all 375ms ease-in-out;
    max-width: 210px;
    /* margin-left: 13px; */
    margin-top: -4px;
}
.select_mate select {
    position: absolute;
    overflow: hidden;
    height: 0px;
    opacity: 0;
    z-index: -1;
}
.cont_list_select_mate {
    position: relative;
    float: left;
    width: 100%;
}
.cont_select_int {
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 999;
    overflow: hidden;
    height: 0px;
    width: 100%;
    background-color: #fff;
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
    border-radius: 0px 0px 3px 3px;
    /* box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.2); */
    transition: all 375ms ease-in-out;
}
.cont_select_int li {
    position: relative;
    float: left;
    width: 100%;
    border-bottom: 1px solid #e0e0e0;
    background-color: #f5f5f5;
    list-style-type: none;
    padding: 10px 10%;
    margin: 0px;
    transition: all 275ms ease-in-out;
    display: block;
    cursor: pointer;
}
.cont_select_int li:last-child {
    border-radius: 3px;
    border-bottom: 0px;
}
.cont_select_int li:hover {
    background-color: #eee;
}
.cont_select_int .active {
    background-color: #eee;
}
/* etiqueta <p> con la opcion selecionada */
.selecionado_opcion {
    padding:10px 10px;
    width: 100%;
    display: block;
    margin: 0px;
    cursor: pointer;
}

.details-views .form-control {
    padding: 12px 10px;
    /* box-shadow: rgba(0, 0, 0, 0.3) 1px 2px 10px -2px; */
    border-radius: 9px;
    border: 0;
}

.tournament-views {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 70px;
    -webkit-gap: 30px;
    -moz-gap: 30px;
    -o-gap: 30px;
    /* justify-content: flex-start; */
    margin: 0 auto;
}

.inner-wrap.details-views.details-teams .content {
    width: 100%;
    max-width: 297px;
    margin-left: 10px;
}

.league-history.upcoming-l .completed {
    background-color: #27ae60;
    color: #fff;
}
.veiw-tournaments .inner-wrap.details-views.details-teams {
    padding: 19px;
    box-shadow: 0px 4px 16px 0px rgb(0 0 0 / 2%);
}
.green-txt {
    color: #27AE60;
}
.orange-txt {
    color: #f2592a;
}

/* .count {
    background: aliceblue;
    width: 100%;
    max-width: 199px;
    padding: 9px 16px;
    text-align: center;
    border-radius: 9px;
} */

/* .count {
    border: 1px solid #0003;
    border-radius: 15px;
    width: 100%;
    max-width: 811px;
    margin: auto;
}

.tournament-views .league-history:hover {
    border: 2px solid #27ae60;
}
.count table.table {
    overflow: hidden;
    border-radius: 18px;
    text-align: center;
} */

.count {
    width: 100%;
    max-width: 165px;
    background-color: #f8f8f8;
    border-radius: 11px;
    padding: 18px 10px;
    text-align: center;
    color: #777;
    /* color: rgb(40, 32, 65); */
    transition: 0.4s;
    transform: scale(1);
    vertical-align: top;
    display: block;
    cursor: pointer;
    border-radius: 11px ;
    height: 60px;
}
.count i.fa-solid {
    font-size: 27px;
    margin-bottom: 16px;
}


.team-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

.count:hover {
    background: #ffe49d;
    color: #000;
}
.count:active {
    background: #ffe49d;
    color: #000;
}
a.league-history {
    color: #000;
    text-decoration: none;
}
.count.active {
    color: #000;
    background: #ffe49d;
}
table.table.tab-table span.green-txt {
    margin-left: 1px;
}
.action-btn .btn.btn-success {
    background: #27ae60;
    border: 0;
    width: 100%;
    max-width: 113px;
    text-align: center;
}
table.table.tab-table tbody tr td.action-btn {
    padding: 20px 0px;
}
table.table.tab-table tbody tr td.divsn {
    padding: 20px 53px;
    margin-right: 116px;
}
a.link-text {
    color: #000;
    text-decoration: none;
}
.coch-contact-form {
    display: flex;
    justify-content: space-around;
    /* align-items: center; */
}
.box {
    border: 1px solid #ccc;
    margin-left: 85px;
    border-radius: 8px;
    padding: 6px 16px;
}
img.coch-profile {
    width: 57px;
    border-radius: 9px;
    height: 57px;
    margin-right: 21px;

}
/* Bootstrap 5 text input with search icon */

.search-team .has-search .form-control {
    padding-left: 2.375rem;
}

.search-team .has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
.form-group.has-search {
    margin-top: -16px;
}

.card_dd {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

section.detail-banner {
    width: 100%;
    margin-top: 120px;
}

.sports_game_f{
    display: block;
    text-align: left;
    font-size: 12px;
    color: #898989;
    font-style: italic;
}

.ongoing-legue .nfl-win {
    background: #ffe49d;
}

.ongoing-legue .nfl-win p {
    color: #000;
}

.detail-banner {
    margin-top: 120px;
}

.form-detail input[type=number]::-webkit-inner-spin-button, 
.form-detail input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
}

.form-detail input[type=number] {
  -moz-appearance: textfield;
}

.form-error {
    position: relative;
    margin-bottom: 30px !important;
    margin-top: 20px !important;
}

.form-error .invalid-feedback {
    position: absolute;
}

@media(min-width: 320px) and (max-width: 768px){
    span.game_title_org {
        font-size: 14px;
    }
}


.privacy h5 ,h3 h6 {
    color: #548dd4;
}
.privacy h3 {
    color: #548dd4;
}

.privacy h6 {
    color: #548dd4;
}

.address-text p {
    margin-bottom: 2px;
    padding: 0rem 3rem;
    font-weight: 500;
}
.privacy p {
    font-weight: 500;
}
li {
    font-weight: 400;
}
.privacy-policies.mt-5 {
    padding: 3rem 3rem;
}

.organizer-dashboard{
    padding-top: 6rem;
}

.upcoming-le h6.game_title_org {
    color: #000;
    font-size: 24px;
}

.upcoming-le h6 {
    color: #000;
    font-size: 24px;
}

.details-views .form-control:focus {
    background-color: #fff !important;
}
.location.text-align-center {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    gap: 20px;
}

