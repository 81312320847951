body {
    font-family: "Roboto", sans-serif !important;
}

@font-face {
    font-family: "Roboto", sans-serif;
    src: url("../fonts/Roboto-Regular.ttf");
  }


  .No-game.Brackets-fileds {
    text-align: center;
    height: 324px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.RosterSheet_background {
  width: 70%;
  background: #fff;
  /* padding-right: 15px; */
  /* padding-left: 15px; */
  padding-bottom: 15px;
  border-radius: 10px;
  /* height: 73vh;
  overflow-y: auto;
  overflow-x: hidden; */
  margin: auto;
}
.RosterSheet {
  padding: 1rem 3rem;
}
.Team-details label {
  text-align: justify;
}
.tracked_table input.form-control {
  border-color: transparent;
  background: transparent;
  height: 20px;
}
.tracked_table tr {
  border-top: 1px solid #938787 !important;
  border-bottom: 1px solid #534b4b !important;
}
/* .RosterSheet td, th{
  border-width: 1px !important;
} */

.RosterSheet label {
  margin: 0;
}

.tracked_table .table-striped>tbody>tr:nth-child(even) {
  --bs-table-bg-type: #969696 !important;
}

.tracked_table th td {
 color:#000 !important;
 /* border-color: #000 !important; */
 vertical-align: middle;
 text-align: center;
 border-top: 1px solid;
}
.feedbackform textarea#comments {
  height: auto;
  border: 0;
}

.feedbackform .form-check.form-check-inline {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000 !important;
}
.feedbackform .form-check-input {
  width: 20px;
  height: 21px;
  border: 1px solid;
}

.form-team label {
  border-right: 1px solid #000;
}
.form-team input {
  border: 0 !important;
}
.form-team .form-check label.form-check-label {
  border: 0;
}
.file-uploaded-content a.text-dark {
  word-break: break-all;
}
.RosterSheet_background td {
  color: #000 !important;
}
.RosterSheet_background td, th {
  /* border-width: 1px !important; */
  /* border-color: #000 !important; */
  /* border-right: 1px solid !important; */
}
.Upload_Logo_Roster {
  width: 90%;
  margin: auto;
}

.important-th {
  border-width: 1px !important;
  border-color: #000 !important;
  font-size:  17px !important;
  
}

.divison_group.roster-table input[type="checkbox"] {
  width: 35px;
  height: 19px;
}
textarea.form-control {
  height: auto;
}
.roaster-top-nav {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: 0;
}


.tracked_table input.form-control:focus {
  background: transparent !important;
  height: 20px;
  border-color: transparent !important;
}
.tracked_table input.form-control:active {
  background: transparent !important;
  height: 20px;
  border-color: transparent !important;
}



.form-team input:focus {
  background-color: transparent !important;
}


.form-team input:active {
  background-color: transparent !important;
}
/*  */
