
.fixed-top .button_green{
    background: #27AE60;
    border-radius: 35px;
    color: #fff !important;
}

.fixed-top .button_green:hover{
    color: #fff !important;
}

.fixed-top ul.navbar-nav.ms-auto.d-none.d-lg-inline-flex.icon {
    justify-content: center;
    align-items: center;
}

.fixed-top .header-menu li.nav-item .nav-link .button_green{
    padding: 10px 20px;
}

.fixed-top .header-menu li.nav-item .nav-link.button_green span{
    margin-left: 0;
    margin-right: 10px;
}
