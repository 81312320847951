.dues {
    background: linear-gradient(180deg, #298CEA 0%, #0257A8 100%);
    width: 296px;
    height: 32px;
    border-radius: 22px;
    font-size: 16px;
    color: #fff;
    padding: 4px 1px;
    text-align: center;
    margin-left: 69px;
    font-style: italic;
}

.Amount-paid {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem 0rem;
}
.amoun-details {
    border: 1px solid #298CEA;
    min-width: 300px;
    min-height: 135px;
    border-radius: 12px;
    text-align: center;
    padding: 10px 21px;
    background: #298cea0a;
}
span.amount-player {
    font-size: 20px;
    color: #484848;
}

.players-amout {
    border-bottom: 1px solid #4848484a;
    padding-bottom: 1px;
}
.amount-date {
    margin-top: 1rem;
}
.payments-details {
    padding-top: 2rem;
}

.listing-wrap .Standing-tabs a.nav-link {
    background: aliceblue;
    margin-right: 16px;
    color: #000;
    font-size: 13px;
    padding: 9px 30px;
}


.listing-wrap .Standing-tabs a.nav-link.active {
    background-color: #42a16a !important;
    color: #fff;
}

td.algin-iitems-box {
    padding-top: 33px !important;
}

form.form_homepage_landing {
    position: relative;
}
.form-error .invalid-feedback {
    position: absolute;
}
/* 
table {
    width: 100%;
    border-collapse: collapse;
  }
  
  tbody {
    text-align: center;
  } */

  .no-games {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: #f2592a;
    margin-left: 20rem;
}

.dues-season-payments-register {
    background: aliceblue;
    border-radius: 22px;
    color: #fff;
    font-size: 16px;
    font-style: italic;
    height: 32px;
    margin-left: 69px;
    padding: 4px 1px;
    text-align: center;
    width: 296px;
}

.guess-position p {
    margin-bottom: 0;
}
span.position-set {
    background-color:#42a16a;
    color: #fff;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 11px;
    /* margin-left: 5px !important; */
    width: 63px;
    height: 20px;
    text-align: center;
}

p.scror-box.ms-2 {
    background-color: #83838338;
    text-align: center;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    padding-top: 3px;
    color: #000 !important;
    font-weight: 500 !important;
}


.winner-accept h6 {
    font-size: 14px;
    white-space: break-spaces;
    width: 200px;
   
}

.winner-accept {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.winner-view {
    display: flex;
}

ul.sub-nav .tab-content tr.algin-iitems-box-1 td.algin-iitems-box {
    font-size: 14px;
    padding: 20px 18px !important;
}

span.position-set.drawmatch {
    background-color: #f2592a;
}