.banner-outline {
    width: 595px;
    min-height: 167px;
    border-radius: 7px;
    padding: 36px 28px 0px 58px;
    border: 1px solid #fff9;
    background: #ffffff0d;
    position: relative;
    box-shadow: 0 0px 0px 0 #ffffff, 0 0px 1px 0 #ffffff;
}

.key-1 span {
    background: linear-gradient(180deg, #A2A2A2 0%, #FFFFFF 31.5%, #D9D9D9 72.5%, #A3A3A3 100%);
    font-size: 19px;
    padding: 6px 36px;
    border-radius: 15px;
    font-style: italic;
}

.key-2 span {
    color: #eee;
    font-size: 28px;
    font-weight: 600;
}

.key-2 {
    margin-left: 22px;
    margin-top: -5px;
}

.keys-top {
    position: absolute;
    top: -17px;
    display: flex;
}

.banner-outline {
    width: 595px;
    min-height: 167px;
    border-radius: 7px;
    padding: 36px 28px 19px 58px;
    background: #ffffff0d;
    position: relative;
    box-shadow: 0 1px 4px 0 #ffffff, 0 14px 20px 0 rgba(0, 0, 0, -10.81);
}

span.amount-dollar {
    font-size: 46px;
    font-weight: 700;
}

.dues-season-payments {
    background: linear-gradient(180deg, #298CEA 0%, #0257A8 100%);
    width: 296px;
    height: 32px;
    border-radius: 22px;
    font-size: 16px;
    color: #fff;
    padding: 4px 1px;
    text-align: center;
    margin-left: 69px;
    font-style: italic;
}
.season-payments {
    position: absolute;
    bottom: -22px;
    left: 0;
}

.spinner-border {
    --bs-spinner-width: 1rem !important;
    --bs-spinner-height: 1rem !important;
}

button.btn.btn-primary:active {
    background: #f1582b;
    border: 0;
}

.search-btn button.btn.btn-primary {
    width: 155px;
}
.content-view-details {
    position: absolute;
    top: 50%;
    left: 11%;
}

.no-view-details-page {
    position: relative;
}

.content-view-details p {
    font-size: 48px;
    font-weight: 500;
}

.no-image img {
    width: 100%;
}