body {
    font-family: "Roboto", sans-serif !important;
}

@font-face {
    font-family: "Aileron", sans-serif;
    src: url("../../assets/fonts/Roboto-Regular.ttf");
}




.header-menu .navbar {
    background: transparent !important;
}

.header-menu li.nav-item {
    list-style: none;
}

.header-menu .navbar_header_flex {
    display: flex;
    flex-direction: row;
}

.header-menu .games_btn {
    background: #27AE60 !important;
    border-radius: 75px;
    margin-right: 10px;
}

.header-menu .signin-btn {
    background: transparent !important;
    border: 1px solid #f1592a !important;
    padding: 8px 10px;
    border-radius: 7px;
    color: #f1592e !important;
}

.header-menu .signin-btn i {
    position: relative;
    top: 3px;
}

.header-menu .signin-btn:hover {
    background: #f1592e !important;
    color: #fff !important;
}


.header-menu .games_btn span {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -2px;
}

.banner-heading {
    width: 100%;
    min-height: 820px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.dashboard-img {
    width: 100%;
    min-height: 785px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.dashboard-img .frame {
    background-size: cover;
    background-repeat: no-repeat;
}

.dashboard-img .frame {
    background-size: cover;
    background-repeat: no-repeat;
    width: 171px;
    height: 355px;
    z-index: 3;
    position: absolute;
    content: '';
    right: 90px;
    bottom: 19%;
}

.dashboard-img video {
    width: 100%;
    max-width: 155px;
    height: 355px;
    position: absolute;
    left: 8px;
    top: -3px;
    border-radius: 22px;
}

.dashboard-img .laptop_image {
    position: absolute;
}

.button_li {
    display: flex;
}

.dashboard-img .laptop_image {
    width: 100%;
    max-width: 546px;
    height: 324px;
    object-fit: cover;
}

.banner-btn.mt-5 {
    display: flex;
    gap: 10px;
    -webkit-gap: 10px;
    -moz-gap: 10px;
    -o-gap: 10px;
}

@media (min-width: 320px) and (max-width: 780px) {
    .swiper-button-next {
        right: 20px !important;
    }

    .fixed-top .navbar {
        background-color: #fff !important;
    }

    .header-menu li.nav-item {
        list-style: none;
        text-align: center;
    }

    .fixed-top .header-menu li.nav-item {
        border-bottom: 1px dashed #000;
        border-radius: 0;
    }

    .fixed-top .header-menu li.nav-item:hover {
        border-bottom: 1px dashed #f2592a;
    }

    .header-menu .navbar_header_flex {
        display: flex;
        flex-direction: column;
    }

    .fixed-top .header-menu .mobile_buttons li.nav-item {
        border-bottom: none;
    }

    .mobile_buttons li a button {
        width: 100%;
        display: block;
        margin-bottom: 11px;
        text-decoration: none !important;
        border-bottom: none !important;
        margin-top: 10px;
    }

    .mobile_buttons .nav-item a {
        position: relative;
        text-decoration: none;
    }

    .button_li {
        display: flex;
        flex-direction: column;
    }

    .dashboard-img video {
        max-width: 125px;
        height: 305px;
        top: -11px;
    }

    .dashboard-img .frame {
        background-size: contain;
        width: 141px;
        height: 305px;
    }

    .download-the-app .inner-wrap .content {
        max-width: 100%;
        margin-left: 0;
    }

    .dashboard-img .laptop_image {
        position: absolute;
        top: 150px;
    }

    .dashboard-img .frame {
        bottom: 9%;
    }

    .inner-wrap.details-views.details-teams .content {
        margin-left: 0 !important;
        margin-top: 10px;
        max-width: 100% !important;
    }

    select#yourSelectId {
        width: 100%;
        max-width: 100% !important;
    }
}

@media (max-width: 992px) {

    .fixed-top .navbar {
        background-color: #fff !important;
    }

    .header-menu li.nav-item {
        list-style: none;
        text-align: center;
    }

    .fixed-top .header-menu li.nav-item {
        border-bottom: 1px dashed #000;
        border-radius: 0;
    }

    .fixed-top .header-menu li.nav-item:hover {
        border-bottom: 1px dashed #f2592a;
    }

    .header-menu .navbar_header_flex {
        display: flex;
        flex-direction: column;
    }

    .fixed-top .header-menu .mobile_buttons li.nav-item {
        border-bottom: none;
    }

    .mobile_buttons li a button {
        width: 100%;
        display: block;
        margin-bottom: 11px;
        text-decoration: none !important;
        border-bottom: none !important;
        margin-top: 10px;
    }

    .mobile_buttons .nav-item a {
        position: relative;
        text-decoration: none;
    }

    .button_li {
        display: flex;
        flex-direction: column;
    }

    /* 
    .reverse_section{
        display: flex;
        flex-direction: column-reverse;
    } */
    .banner-heading {
        min-height: 325px;
        text-align: center;
    }

    .dashboard-img {
        min-height: 525px;
    }

    .dashboard-img .laptop_image {
        object-fit: contain;
    }

    .banner-btn .league.start {
        width: 100%;
    }

    .banner-btn .tounament {
        width: 100%;
    }

    .banner-btn.mt-5 {
        display: flex;
        flex-direction: column;
    }

    .portal {
        width: 100%;
        text-align: center;
    }

    .portal h4 {
        width: 100%;
        margin-top: 15px;
    }

    .tournament-leak-btn.mt-5 {
        margin-top: 0 !important;
        margin-bottom: 30px;
    }

    .footer-widget ul {
        justify-content: center !important;
        gap: 10px;
    }

    .footer-widget .nav li.nav-item {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .icons a i.fa-brands {
        padding-right: 30px !important;
    }
}

@media (min-width: 781px) and (max-width: 1023px) {
    .dashboard-img {
        min-height: 525px;
        position: relative;
        top: 60px;
    }

    .inner-wrap.details-views.details-teams {
        flex-direction: column;
    }

    .inner-wrap.details-views.details-teams .content {
        margin-left: 0 !important;
        margin-top: 10px;
        max-width: 100% !important;
    }

    select#yourSelectId {
        width: 100%;
        max-width: 100% !important;
    }

    .footer-widget .nav li.nav-item {
        margin: 20px 0;
    }
}


.find-tournaments-legues {
    margin-top: 12rem;
}


.Tournament-box {
    width: 100%;
    min-height: 514px;
    border-radius: 20px;
    background: #fff;
    padding: 32px 41px 20px 41px;
    max-width: 518px;
    margin: auto;
    /* min-width: 477px; */
}

.startup-video img {
    width: 80%;
}

.Tournament-box h4 {
    color: #000;
    font-weight: 700;
    font-size: 32px;
}

.Tournament-box label.form-label {
    color: #0000007a;
    font-family: Roboto;
}


.Tournament-box .form-control {
    background: #e9e9e9ab;
    border: 0;
    padding: 11px 14px;
}

select#formGridState {
    background-color: #e9e9e9ab;
    border: 0;
    padding: 11px 14px;
}

select#formGridCity {
    background-color: #e9e9e9ab;
    border: 0;
    padding: 11px 14px;
}

.form-select:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none !important;
}

.form-control:focus {

    background-color: #e9e9e9ab !important;
    border-color: transparent !important;
    outline: 0;
    box-shadow: none !important;
}


.form-select {
    cursor: pointer;
}

input[type=date] {
    position: relative;
    border: 1px solid #d4d4d4;
}

input[type=date]::before {
    position: absolute;
    content: '';
    width: 19px;
    height: 19px;
    background: #fff;
    right: 9px;
}

input[type=date]::after {
    position: absolute;
    content: '';
    width: 25px;
    height: 28px;
    background-image: url("../../Img/images/calendar_icon.png");
    right: 9px;
    top: 7px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    position: absolute;
    right: 9px;
    height: 28px;
    width: 28px;
    z-index: 2;
}

button.w-100.mt-5.btn.btn-primary:active {
    background-color: #f2592a;
    border-color: transparent;
}


h3.spacing {
    font-size: 51px;
    font-weight: 300;
}

.create-tournamet-ticks li {
    list-style: none;
    font-size: 18px;
    font-weight: 400;
}

.benifit-list {
    display: flex;
    align-items: center;
}

.banner-title {
    margin-top: 8rem;
}

h3.spacing strong {
    font-weight: 500;
}

.find-tournaments-legues button.btn.btn-primary {
    padding: 12px 45px 11px 42px;
    border-radius: 10px;
    background-color: #f2592a;
    color: #fff;
    border: 0px;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
}

.league-history.swiper-slide {
    margin-left: 11px;
    margin-right: 33px;
}



.cont {
    /* background: #00887a; */
    /* height: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: red;
    margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 573px) {
    section.tournamnt-slider h3 {
        font-weight: 500;
        font-size: 26px;
    }
    .portal img {
        width: 100%;
    }
    .swiper-button-next, .swiper-button-prev {
        display: none;
    }

}