body {
  margin: 0;
  font-family: "Aileron-Regular", sans-serif;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* @font-face {
  font-family: "Aileron-Regular", sans-serif;
  src: url("./assets/fonts/Aileron-Regular.woff");
}

*{
  font-family: "Aileron-Regular", sans-serif;
} */