.schedule_tab_wrap .nav-item .nav-link{
    color: #000;
}

.schedule_tab_wrap .nav-item .nav-link.active{
    border: none;
    color: #f1592a;
    border-bottom: 1px solid;
}

.form-detail .font-weight-bold{
    font-weight: 700;
}

.recaptcha{
    width: 100%;
    margin-bottom: 10px;
}

p.form_subbmitted {
    width: 100%;
    text-align: center;
}

.form_subbmitted span img {
    max-width: 84px;
    display: block;
    text-align: center;
    margin: 0 auto;
}

@media(min-width: 320px) and (max-width: 768px){
    .org-details {
        flex-direction: column;
        text-align: center;
    }
    .org-content .email {
        justify-content: center;
    }
    .org-content .phone {
        justify-content: center;
    }
}