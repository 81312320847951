
.select-view {
    flex-direction: column;
    display: flex;
}

select#yourSelectId {
    width: 100%;
    max-width: 150px;
    height: 48px;
    border: none;
    padding: 7px;
    border-radius: 9px;
    min-width: 161px;
}

select#yourSelectId:focus-visible, select#yourSelectId:focus {
    border: none;
}

.pagination_section {
    width: 100%;
    margin: 30px 0;
    display: flex;
    justify-content: center;
}

.win.t-win .basket-img {
    width: 100px;
    height: 80px;
    border-radius: 50%;
}

@media(min-width: 320px) and (max-width: 1240px){
    .team-info {
        justify-content: flex-start !important;
    }
}


select#yourSelectId2 {
    width: 100%;
    max-width: 150px;
    height: 48px;
    border: none;
    padding: 7px;
    border-radius: 9px;
}